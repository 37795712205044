import { FontAssets, FontType } from '@packages/types'
import { generateId } from '@packages/unique-string'
import { Base64 } from 'js-base64'

import type { EngravingPostProcessingOperation, NodePostProcessingOperation } from 'customizer/2dDisplayer/types/node'
import FontService from 'utils/loaders/font/FontService'

import engravingFilter from './filters/svg/engravingFilter'

export interface TextSVGParams {
  text: { value?: string }
  color?: { hex?: string }
  font: { size: string; family: string; assets: FontAssets; fontType?: FontType }
  outline?: { hex?: string; width?: string }
  postProcessingOperations?: NodePostProcessingOperation[]
}

export default class TextSVG {
  static ns = 'http://www.w3.org/2000/svg' as const
  static maxTestIterations = 20 as const

  public defaultValues = {
    text: '',
    color: '#000000',
    fontSize: '30px',
    fontFamily: 'Times',
  }

  protected svgElement
  protected defsElement

  private image: HTMLImageElement | undefined

  constructor() {
    this.svgElement = document.createElementNS(TextSVG.ns, 'svg')

    const styleDef = document.createElementNS(TextSVG.ns, 'style')
    styleDef.setAttributeNS(null, 'id', 'font-style')
    styleDef.setAttributeNS(null, 'type', 'text/css')
    this.defsElement = document.createElementNS(TextSVG.ns, 'defs')
    this.defsElement.appendChild(styleDef)
    this.svgElement.prepend(this.defsElement)
  }

  public getAttribute(attribute: string) {
    return this.svgElement.getAttributeNS(null, attribute)
  }

  public toSVG() {
    return this.svgElement.cloneNode(true) as SVGSVGElement
  }

  public toCanvas() {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')!

    if (!this.image) return canvas

    canvas.width = this.image.width
    canvas.height = this.image.height
    ctx.drawImage(this.image, 0, 0)

    return canvas
  }

  public async toImage() {
    const xml = new XMLSerializer().serializeToString(this.svgElement)
    const base64Image = `data:image/svg+xml;base64,${Base64.encode(xml)}`

    return new Promise<HTMLImageElement>(resolve => {
      const image = new Image()
      image.onload = () => {
        this.image = image
        this.waitForResourceForSafariBug().then(() => resolve(image))
      }
      image.src = base64Image
    })
  }

  public destroy() {
    this.finishWork()
  }

  /*
  This is a dirty fix for a bug where safari calls image.onload
  before the image is actually ready for SVG converted to data urls
  containing and embedded font
  */
  private async waitForResourceForSafariBug() {
    const isSafari = navigator.userAgent.includes('Safari') && !navigator.userAgent.includes('Chrome')

    if (isSafari) {
      await new Promise<void>(async resolve => setTimeout(() => resolve(), 100))
    }
  }

  protected beginWork() {
    this.svgElement.style.position = 'fixed'
    this.svgElement.style.left = '-2000px'
    if (!document.body.contains(this.svgElement)) document.body.appendChild(this.svgElement)
  }

  protected finishWork() {
    if (document.body.contains(this.svgElement)) document.body.removeChild(this.svgElement)
    this.svgElement.style.position = 'initial'
    this.svgElement.style.left = 'initial'
  }

  protected async setFont(params: TextSVGParams) {
    let encodedFont: string | null | undefined

    try {
      await FontService.loadFont({
        fontFamily: params.font.family,
        type: params.font.fontType,
        asset: params.font.assets,
      })

      encodedFont = await FontService.generateEncodedFontCss({
        fontFamily: params.font.family,
        type: params.font.fontType,
        asset: params.font.assets,
        preview: params.text.value,
      })
    } catch (err) {
      console.error(err)
    }

    this.beginWork()
    this.defsElement.querySelector('#font-style')!.innerHTML = encodedFont || ''

    if (encodedFont) await this.waitForFontToBeLoaded(params.font.family)
    this.finishWork()
  }

  private async waitForFontToBeLoaded(fontFamily: string) {
    const id = generateId(`text-path-test-font`)

    const testFontPath = document.createElementNS(TextSVG.ns, 'path')
    testFontPath.setAttributeNS(null, 'd', 'M 0 0 L 0 2000')
    testFontPath.setAttributeNS(null, 'id', id)

    this.svgElement.appendChild(testFontPath)

    const sansSerifText = document.createElementNS(TextSVG.ns, 'text')
    const serifText = document.createElementNS(TextSVG.ns, 'text')
    const sansSerifTextPath = document.createElementNS(TextSVG.ns, 'textPath')
    sansSerifTextPath.setAttributeNS(null, 'href', `#${id}`)
    const serifTextPath = document.createElementNS(TextSVG.ns, 'textPath')
    serifTextPath.setAttributeNS(null, 'href', `#${id}`)

    sansSerifText.appendChild(sansSerifTextPath)
    serifText.appendChild(serifTextPath)

    this.svgElement.appendChild(sansSerifText)
    this.svgElement.appendChild(serifText)

    sansSerifTextPath.appendChild(document.createTextNode('AbC'))
    serifTextPath.appendChild(document.createTextNode('AbC'))

    sansSerifText.setAttributeNS(null, 'font-family', 'sans-serif')
    serifText.setAttributeNS(null, 'font-family', 'serif')

    const sansSerifInitialLength = sansSerifTextPath.getComputedTextLength()
    const serifInitialLength = serifTextPath.getComputedTextLength()

    sansSerifText.setAttributeNS(null, 'font-family', `${fontFamily}, sans-serif`)
    serifText.setAttributeNS(null, 'font-family', `${fontFamily}, serif`)

    await new Promise<void>(async resolve => {
      let i = 0
      while (
        sansSerifTextPath.getComputedTextLength() === sansSerifInitialLength &&
        serifTextPath.getComputedTextLength() === serifInitialLength &&
        i < TextSVG.maxTestIterations
      ) {
        await new Promise(resolve => setTimeout(resolve, 10))
        i = i + 1
      }

      resolve()
    })

    this.svgElement.removeChild(sansSerifText)
    this.svgElement.removeChild(serifText)
    this.svgElement.removeChild(testFontPath)
  }

  protected createSVGFilters(params: TextSVGParams) {
    const filters = this.svgElement.querySelectorAll('filter')
    filters.forEach(filter => this.defsElement.removeChild(filter))

    const engravingOperation = params.postProcessingOperations?.find(
      operation => operation.type === 'engraving'
    ) as EngravingPostProcessingOperation
    if (engravingOperation) {
      const filter = engravingFilter(
        engravingOperation.lightDirection,
        engravingOperation.depth,
        engravingOperation.sharpness,
        TextSVG.ns
      )
      this.defsElement.appendChild(filter)

      return filter
    }
    return
  }

  protected hasOutline(params: TextSVGParams) {
    return !!params.outline?.hex && !!Number(params.outline?.width)
  }
}
