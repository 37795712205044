import { CustomizableQuestion, Rule, RuleThenType } from '@packages/types'
import { omit, clone } from 'lodash'

import getFlagsmith from 'customizer/featureFlags/flagsmith'
import type { RootState } from 'customizer/store'

import AssertableObject from '../AssertableObject'
import { getFirstAvailableAnswer, isCurrentAnswerRestricted, validRulesSelector } from '../selectors'
import { getQuestionRestrictedAnswers } from '../utils'
// eslint-disable-next-line import/no-cycle
import optimizedApplyRules from './optimizedApplyRules'
import type { CustomizationState } from './reducer'
// eslint-disable-next-line import/no-cycle
import selectAnswer from './selectAnswer'
// eslint-disable-next-line import/no-cycle
import selectAnswers from './selectAnswers'

const applyRules = (state: CustomizationState) => {
  const rules: Rule[] = validRulesSelector({ customization: state } as RootState)
  let nextState = state
  for (const rule of Object.values(rules)) {
    const trigger = rule.when.reduce((isTriggered, statement) => {
      return (
        new AssertableObject(statement.path, statement.assertion, statement.value).evaluate(nextState) && isTriggered
      )
    }, true)

    nextState = trigger ? activateRule(nextState, rule) : deactivateRule(nextState, rule)
  }

  return nextState
}

const getRestrictions = (state: CustomizationState, rule: Rule) => {
  const restriction = rule.then[0]

  switch (restriction.type) {
    case RuleThenType.SetAuthorizedAnswers:
      return {
        questionId: restriction.questionId,
        type: restriction.type,
        answers: state.questions[restriction.questionId].answers.filter(id => !restriction.payload?.includes(id)),
      }
    case RuleThenType.SetRestrictedAnswers:
      return {
        questionId: restriction.questionId,
        answers: restriction.payload,
        type: restriction.type,
      }
    case RuleThenType.AddRestriction:
      return {
        questionId: restriction.questionId,
        answers: [restriction.answerId],
        type: restriction.type,
      }
    case RuleThenType.RestrictAllBut:
      const answers = restriction.payload
        ? state.questions[restriction.questionId].answers.filter(id => !restriction.payload?.includes(id))
        : state.questions[restriction.questionId].answers.filter(id => id !== restriction.answerId)

      return {
        questionId: restriction.questionId,
        answers,
        type: restriction.type,
      }
    case RuleThenType.DisableQuestion:
      return {
        questionId: restriction.questionId,
        answers: clone(state.questions[restriction.questionId].answers),
        type: restriction.type,
      }
  }
}

const applyRestrictions = (state: CustomizationState, question: CustomizableQuestion) => {
  const defaultConfiguration = state.customizerProduct.defaultConfiguration[question.id]
  const validAnswer = getFirstAvailableAnswer(question, state.answers, defaultConfiguration as string | null)

  return selectAnswer(state, { payload: { questionId: question.id, answerId: validAnswer } })
}

const applyMultiAnswerRestrictions = (state: CustomizationState, question: CustomizableQuestion) => {
  const restrictedAnswers = getQuestionRestrictedAnswers(question)
  const answerIds = question.selectedAnswers!.filter(id => !restrictedAnswers.includes(id))

  return selectAnswers(state, { payload: { questionId: question.id, answerIds } })
}

export const activateRule = (state: CustomizationState, rule: Rule) => {
  if (!rule.then[0]) return state

  const question = state.questions[rule.then[0].questionId]

  if (!question || question.restrictions![rule.id] != null) return state

  const restriction = getRestrictions(state, rule)

  const updatedQuestion = {
    ...state.questions[question.id],
    restrictions: {
      ...state.questions[question.id].restrictions,
      [rule.id]: { answers: restriction.answers, type: restriction.type },
    },
  }

  const nextState = { ...state, questions: { ...state.questions, [question.id]: updatedQuestion } }

  if (!isCurrentAnswerRestricted(updatedQuestion)) return nextState

  return question.isMultiAnswer
    ? applyMultiAnswerRestrictions(nextState, updatedQuestion)
    : applyRestrictions(nextState, updatedQuestion)
}

export const deactivateRule = (state: CustomizationState, rule: Rule) => {
  if (!rule.then[0]) return state

  const question = state.questions[rule.then[0].questionId]

  if (!question || !question.restrictions![rule.id]) return state

  const questionWithoutRestriction = { ...question, restrictions: omit(question.restrictions, rule.id) }

  if (!question.isMultiAnswer && !question.selectedAnswer) {
    questionWithoutRestriction.selectedAnswer = getFirstAvailableAnswer(
      questionWithoutRestriction,
      state.answers,
      state.customizerProduct.defaultConfiguration[question.id] as string | null
    )
  }

  return { ...state, questions: { ...state.questions, [question.id]: questionWithoutRestriction } }
}

export default (...args: Parameters<typeof applyRules>) => {
  return getFlagsmith().hasFeature('optimized_rules') ? optimizedApplyRules(...args) : applyRules(...args)
}
