import applyLinkedQuestions from './applyLinkedQuestions'
// eslint-disable-next-line import/no-cycle
import applyRules from './applyRules'
import type { CustomizationState } from './reducer'

export type SelectAnswersAction = {
  payload: {
    answerIds: string[]
    questionId: string
  }
}

export const selectAnswers = (
  state: CustomizationState,
  { payload: { answerIds, questionId } }: SelectAnswersAction
): CustomizationState => {
  const question = state.questions[questionId]
  const nextState = {
    ...state,
    questions: {
      ...state.questions,
      [questionId]: { ...question, selectedAnswers: answerIds, hadInteraction: true },
    },
  }

  return nextState
}

export default (...args: Parameters<typeof selectAnswers>): CustomizationState => {
  return applyLinkedQuestions(applyRules(selectAnswers(...args)))
}
