import { AnswerType, EntityType, QuestionType } from '@packages/types'
import { AnyAction } from 'redux'

import applyLinkedQuestions from './applyLinkedQuestions'
import applyRules from './applyRules'
import type { CustomizationState } from './reducer'

const createCustomColorAnswer = (state: CustomizationState, { payload }: AnyAction) => {
  const numberOfViews = state.customizerProduct.views
  const question = state.questions[payload.questionId]

  let answer

  if (question.type === QuestionType.Material) {
    const defaultAnswerId = state.customizerProduct.defaultConfiguration[payload.questionId] as string
    const defaultAnswer = defaultAnswerId ? state.answers[defaultAnswerId] : null
    const viewTemplate = defaultAnswer?.views?.[0] || { lighting: false, lightIntensity: 0, lightThreshold: 0 }

    answer = {
      id: payload.answerId,
      name: payload.color,
      entityType: EntityType.Answer,
      type: AnswerType.Material,
      thumbnail: null,
      isPersonalisation: true,
      views: Array.from(Array(numberOfViews)).map(() => ({
        ...viewTemplate,
        type: AnswerType.Material,
        color: payload.color,
      })),
    }
  } else {
    answer = {
      id: payload.answerId,
      name: payload.color,
      entityType: EntityType.Answer,
      type: AnswerType.Color,
      thumbnail: null,
      isPersonalisation: true,
      views: Array.from(Array(numberOfViews)).map(() => ({ type: AnswerType.Color, color: payload.color })),
    }
  }

  const newState = {
    ...state,
    questions: {
      ...state.questions,
      [payload.questionId]: {
        ...question,
        selectedAnswer: payload.answerId,
        answers: [...question.answers, payload.answerId],
        hadInteraction: true,
      },
    },
    answers: {
      ...state.answers,
      [payload.answerId]: answer,
    },
  }

  return applyLinkedQuestions(applyRules(newState))
}

export default createCustomColorAnswer
