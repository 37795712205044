const engravingFilter = (
  lightDirection: { x: number; y: number },
  depth: number,
  sharpness: number,
  namespace: string
) => {
  const x = lightDirection.x * depth * 12
  const y = -1 * lightDirection.y * depth * 12
  const deviation = (1 - sharpness) * 8
  const filterElement = document.createElementNS(namespace, 'filter')
  filterElement.id = 'engravingFilter'
  filterElement.setAttributeNS(namespace, 'x', '-20%')
  filterElement.setAttributeNS(namespace, 'y', '-20%')
  filterElement.setAttributeNS(namespace, 'width', '140%')
  filterElement.setAttributeNS(namespace, 'height', '140%')
  filterElement.setAttributeNS(namespace, 'color-interpolation-filters', 'sRGB')
  filterElement.innerHTML = `
	<feColorMatrix type="matrix" values="0 0 0 0 0
    0 0 0 0 0
    0 0 0 0 0
    0 0 0 ${depth} 0" x="0%" y="0%" width="100%" height="100%" in="SourceGraphic" result="colormatrix4"/>
  <feGaussianBlur stdDeviation="${deviation} ${deviation}" x="0%" y="0%" width="100%" height="100%" in="colormatrix4" edgeMode="none" result="blur"/> 
  <feOffset dx="${x}" dy="${y} " x="0%" y="0%" width="100%" height="100%" in="blur" result="offset2"/>
  <feComposite in="colormatrix4" in2="offset2" operator="out" x="0%" y="0%" width="100%" height="100%" result="composite7"/>
	<feColorMatrix type="matrix" values="1 0 0 1 0
    0 1 0 1 0
    0 0 1 1 0
    0 0 0 0.7 0" x="0%" y="0%" width="100%" height="100%" in="SourceAlpha" result="colormatrix5"/>
	<feGaussianBlur stdDeviation="0 0" x="0%" y="0%" width="100%" height="100%" in="colormatrix5" edgeMode="none" result="blur2"/>
	<feComposite in="blur2" in2="SourceAlpha" operator="out" x="0%" y="0%" width="100%" height="100%" result="composite6"/>
	<feColorMatrix type="matrix" values="1 0 0 0 0
    0 1 0 0 0
    0 0 1 0 0
    0 0 0 0.4 0" x="0%" y="0%" width="100%" height="100%" in="SourceGraphic" result="colormatrix7"/>
	<feMerge x="0%" y="0%" width="100%" height="100%" result="merge1">
    <feMergeNode in="colormatrix7"/>
		<feMergeNode in="composite7"/>
		<feMergeNode in="composite6"/>
  </feMerge>
`

  return filterElement
}

export default engravingFilter
