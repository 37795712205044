import { FontType, DesignType, OverflowBehavior, PartType } from '@packages/types'

import MultilineTextSVG from 'common/drawing/MultilineTextSVG'
import TextOnPathSVG from 'common/drawing/TextOnPathSVG'
import * as twoDDisplayerActions from 'customizer/2dDisplayer/actions'
import * as twoDDisplayerSelectors from 'customizer/2dDisplayer/selectors'
import * as twoDDisplayerUtils from 'customizer/2dDisplayer/utils'
import getBulkOrderRowDispatch from 'customizer/bulkOrder/getBulkOrderRowDispatch'
import {
  bulkOrderTotalQuantitySelector,
  bulkOrderRowCustomizationSelector,
  bulkOrderRowsSelector,
  bulkOrderGroupSelector,
} from 'customizer/bulkOrder/selectors'
import applyLinkedQuestions from 'customizer/customization/reducer/applyLinkedQuestions'
import * as customizationSelectors from 'customizer/customization/selectors'
import { SummaryType } from 'customizer/customization/selectors'
import dataURItoBlob from 'utils/dataURItoBlob'
import ImageLoader from 'utils/loaders/ImageLoader'
import resizeDataURI from 'utils/resizeDataURI'
import smartResize from 'utils/smartResize'

const {
  ordersheetSelector,
  summarySelector,
  productNameSelector,
  productIdSelector,
  customizerProductIdSelector,
  storeIdSelector,
  designConfigurationSelector,
  personalisationsSelector,
  partsWithPrintSelector,
} = customizationSelectors

const THUMBNAIL_MAX_DIMENSION = 250

export const generateDesignData = async state => ({
  configuration: designConfigurationSelector(state),
  personalisations: personalisationsSelector(state),
  productionData: ordersheetSelector(state),
  summary: summarySelector(state),
  productId: productIdSelector(state),
  productName: productNameSelector(state),
  customizerProductId: customizerProductIdSelector(state),
  store: storeIdSelector(state),
  prints: await generatePrintsData(state),
  type: DesignType.CustomizationDesign,
})

export const getBulkOrderDesignData = async (state, dispatch) => {
  const bulkOrderRowDispatch = getBulkOrderRowDispatch(dispatch)
  const designs = []
  const thumbnails = []

  const bulkOrderGroup = bulkOrderGroupSelector(state)
  const totalQuantity = bulkOrderTotalQuantitySelector(state)
  const summary = [{ key: bulkOrderGroup?.quantity?.name || 'Quantity', value: `${totalQuantity}` }]

  for (const [index, row] of bulkOrderRowsSelector(state).entries()) {
    const rowState = {
      ...state,
      customization: applyLinkedQuestions(bulkOrderRowCustomizationSelector(state.customization, row)),
    }
    const thumbnail = await bulkOrderRowDispatch(index, dispatch => generateDesignThumbnail()(dispatch, () => rowState))
    const design = await generateDesignData(rowState)

    thumbnails.push(thumbnail)
    designs.push({ design, quantity: row.quantity })

    if (row.values.length > 0) {
      const bulkOrderSummaryLines = summarySelector(rowState, { summaryType: SummaryType.BulkOrderQuestionsOnly })
      const bulkOrderSummaryLinesWithQuantity = [
        ...bulkOrderSummaryLines,
        { key: bulkOrderGroup?.quantity?.name || 'Quantity', value: row.quantity },
      ]

      summary.push({
        key: `#${index + 1}`,
        value: bulkOrderSummaryLinesWithQuantity.reduce((rowSummary, { key, value }) => {
          return rowSummary ? `${rowSummary} / ${key}: ${value}` : `${key}: ${value}`
        }, ''),
      })
    }
  }

  const nonBulkOrderQuestionsSummary = summarySelector(state, { summaryType: SummaryType.NonBulkOrderQuestionsOnly })
  summary.push(...nonBulkOrderQuestionsSummary)

  return {
    type: DesignType.BulkOrderDesign,
    parentDesign: null,
    productName: productNameSelector(state),
    productId: productIdSelector(state),
    customizerProductId: customizerProductIdSelector(state),
    summary,
    store: storeIdSelector(state),
    designs,
    thumbnails,
  }
}

const generateLogoPrintData = async part => {
  const logo = part.logo.selectedAnswer.views[0].logo
  const productPreview = part.printArea.productPreview
  const { scale } = productPreview.views[productPreview.designView]
  const position = part.position.position

  const image = await ImageLoader.loadImage(logo.url, { preventResize: true })

  const scaledWidth = position.maxWidth / scale
  const scaledHeight = position.maxHeight / scale
  const { width: adjustedWidth, height: adjustedHeight } = smartResize(image, {
    width: scaledWidth,
    height: scaledHeight,
  })

  const scaledPosition = {
    x: position.x / scale,
    y: position.y / scale,
    width: adjustedWidth,
    height: adjustedHeight,
    rotation: position.rotation,
  }

  return {
    logo,
    position: scaledPosition,
    partRef: part.id,
  }
}

const generateBezierTextPrintData = async (part, dimensions) => {
  const params = twoDDisplayerUtils.textPartToLayer(
    part,
    dimensions,
    part.printArea.productPreview.designView,
    part.printArea
  )
  const textSVG = new TextOnPathSVG()
  await textSVG.build(params)

  const fontView = twoDDisplayerUtils.answerToView(part.font, 0)
  const text = twoDDisplayerUtils.answerToView(part.text, 0).text
  const color = twoDDisplayerUtils.answerToView(part.color, 0)?.color
  const outline = twoDDisplayerUtils.answerToView(part.outline, part.printArea.productPreview.designView)
  const position = twoDDisplayerUtils.answerToView(part.position, part.printArea.productPreview.designView)

  const productPreview = part.printArea.productPreview
  const { scale } = productPreview.views[productPreview.designView]
  const scaledFontSize = textSVG.adjustedFontSize / scale
  const scaledOutline = outline ? { color: outline.color, width: outline.width / scale } : outline

  return {
    text: text || '',
    font: fontView?.assets || {},
    fontType: fontView?.fontType || FontType.Custom,
    fontSize: scaledFontSize,
    outline: scaledOutline,
    position: {
      bezier: position.bezier.map(p => p / scale),
      textAlign: position.textAlign || 'center',
    },
    color: color || '#000000',
  }
}

const generateMultilineTextPrintData = async (part, dimensions) => {
  const fontView = twoDDisplayerUtils.answerToView(part.font, 0)

  const text = twoDDisplayerUtils.answerToView(part.text, 0).text
  let fontSize = twoDDisplayerUtils.answerToView(part.fontSize, part.printArea.productPreview.designView)?.size
  const color = twoDDisplayerUtils.answerToView(part.color, 0)?.color
  const outline = twoDDisplayerUtils.answerToView(part.outline, part.printArea.productPreview.designView)
  const position = twoDDisplayerUtils.answerToView(part.position, part.printArea.productPreview.designView)

  const productPreview = part.printArea.productPreview
  const { scale } = productPreview.views[productPreview.designView]

  let width = position.maxWidth
  let height = position.maxHeight

  if (!width || !height) {
    const params = twoDDisplayerUtils.textPartToLayer(
      part,
      dimensions,
      part.printArea.productPreview.designView,
      part.printArea
    )

    if (!width || !height) {
      const text = new MultilineTextSVG()
      await text.build(params)

      if (!width) width = Math.ceil(text.getAttribute('width'))
      if (!height) height = Math.ceil(text.getAttribute('height'))
    }
  }

  if (position.overflowBehavior === OverflowBehavior.Resize && !part.allowedTransforms?.resize) {
    const params = twoDDisplayerUtils.textPartToLayer(
      part,
      dimensions,
      part.printArea.productPreview.designView,
      part.printArea
    )

    const text = new MultilineTextSVG()
    await text.build(params)

    fontSize = `${text.adjustedFontSize}px`
  } else if (position.overflowBehavior === OverflowBehavior.Visible) {
    const params = twoDDisplayerUtils.textPartToLayer(
      part,
      dimensions,
      part.printArea.productPreview.designView,
      part.printArea
    )

    const text = new MultilineTextSVG()
    await text.build(params)

    height = Math.ceil(text.getAttribute('height'))
  }

  const scaledPosition = {
    maxWidth: width / scale,
    maxHeight: height / scale,
    x: position.x / scale - width / scale / 2,
    y: position.y / scale - height / scale / 2,
    rotation: position.rotation || 0,
    textAlign: position.textAlign || 'center',
    verticalAlign: !part.allowedTransforms.resize ? (position.verticalAlign ?? 'middle') : 'middle',
    sizing: position.maxWidth == null ? 'fluid' : 'fixed',
  }

  const scaledFontSize = fontSize ? +fontSize.replace('px', '') / scale : 30 / scale
  const scaledOutline = outline ? { color: outline.color, width: outline.width / scale } : outline

  return {
    text: text || '',
    font: fontView?.assets || {},
    padding: part.allowedTransforms?.resize || position.overflowBehavior !== OverflowBehavior.Clip ? scaledFontSize : 0,
    fontType: fontView?.fontType || FontType.Custom,
    fontSize: scaledFontSize,
    outline: scaledOutline,
    position: scaledPosition,
    color: color || '#000000',
    overflowBehavior: position.overflowBehavior,
  }
}

const generateTextPrintData = async (part, dimensions) => {
  return part.position.position.shape == null
    ? generateBezierTextPrintData(part, dimensions)
    : generateMultilineTextPrintData(part, dimensions)
}

export const generatePrintsData = async state => {
  const partsWithPrint = partsWithPrintSelector(state)
  const prints = {}

  for (const part of partsWithPrint) {
    let printData

    switch (part.type) {
      case PartType.Logo:
        printData = await generateLogoPrintData(part)
        break
      case PartType.Text:
        printData = await generateTextPrintData(part, twoDDisplayerSelectors.dimensionsSelector(state))
        break
      default:
        continue
    }

    prints[part.printArea.id] = [...(prints[part.printArea.id] || []), printData]
  }

  return prints
}

export const getThumbnailDimensions = state => {
  const productDimensions = twoDDisplayerSelectors.dimensionsSelector(state)
  return productDimensions.width <= THUMBNAIL_MAX_DIMENSION && productDimensions.height <= THUMBNAIL_MAX_DIMENSION
    ? productDimensions
    : productDimensions.width > productDimensions.height
      ? {
          width: THUMBNAIL_MAX_DIMENSION,
          height: (THUMBNAIL_MAX_DIMENSION * productDimensions.height) / productDimensions.width,
        }
      : {
          width: (THUMBNAIL_MAX_DIMENSION * productDimensions.width) / productDimensions.height,
          height: THUMBNAIL_MAX_DIMENSION,
        }
}

export const generateDesignThumbnail = () => {
  return async (dispatch, getState) => {
    const designThumbnail = await dispatch(dispatch => twoDDisplayerActions.generateProductImage(0)(dispatch, getState))
    const thumbnailDimensions = getThumbnailDimensions(getState())

    return resizeDataURI(designThumbnail, thumbnailDimensions)
  }
}

export const generateDesign = () => {
  return async (dispatch, getState) => {
    const state = getState()
    const formData = new FormData()

    // We want to send all designs in one POST request to avoir bloating the backend with multiples POST request
    // Otherwise having 100 rows in a bulk order would result in 100 POST request
    const bulkOrderRows = bulkOrderRowsSelector(state)
    if (bulkOrderRows.length > 0) {
      const { thumbnails, ...bulkOrderDesign } = await getBulkOrderDesignData(state, dispatch)

      formData.append('design', JSON.stringify(bulkOrderDesign))
      thumbnails.forEach(thumbnail => {
        formData.append(`designThumbnail`, dataURItoBlob(thumbnail), `designThumbnail.png`)
      })

      return fetch(`${state.baseURL}/brands/${state.tenant}/designs/bulk-order`, {
        method: 'POST',
        body: formData,
      }).then(response => {
        if (response.ok) return response.json()
        throw new Error(`${response.status} ${response.statusText}`)
      })
    }

    const designData = await generateDesignData(state)
    const thumbnail = await dispatch(generateDesignThumbnail())

    formData.append('design', JSON.stringify(designData))
    formData.append('designThumbnail', dataURItoBlob(thumbnail), `designThumbnail.png`)

    return fetch(`${state.baseURL}/brands/${state.tenant}/designs`, {
      method: 'POST',
      body: formData,
    }).then(response => {
      if (response.ok) return response.json()
      throw new Error(`${response.status} ${response.statusText}`)
    })
  }
}
